import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Response } from '../../interfaces';
import { USER_ROLES } from '../../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentUserRolesSub$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public isLoggedIn$: Observable<boolean> = this.isLoggedInSub$.asObservable();
  public currentUserRoles$: Observable<string[]> = this.currentUserRolesSub$.asObservable();

  constructor(private router: Router, private http: HttpClient, private toastrService: NbToastrService) {
    if (this.adminCredential.token && this.adminCredential.roles) {
      this.login();
    } else {
      this.logout(false);
    }
  }

  get adminCredential() {
    const adminCredential = sessionStorage.getItem('platformAdminCredential');
    if (adminCredential) {
      try {
        return JSON.parse(adminCredential);
      } catch (error) {
        return { token: null, roles: [] };
      }
    }
    return { token: null, roles: [] };
  }

  set adminCredential(token: { token: string | null; roles: string[] }) {
    if (token) {
      sessionStorage.setItem('platformAdminCredential', JSON.stringify(token));
    } else {
      sessionStorage.removeItem('platformAdminCredential');
    }
  }

  login() {
    this.isLoggedInSub$.next(true);
    this.currentUserRolesSub$.next(this.adminCredential.roles);
  }

  checkUserRights(userRoles: string[]): boolean {
    return userRoles.some((roleToCheck) => this.currentUserRolesSub$.getValue().includes(roleToCheck));
  }

  getIsLoggedInSnapshot(): boolean {
    return this.isLoggedInSub$.getValue();
  }

  stringToBase64(str: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    return btoa(String.fromCharCode(...data));
  }

  getUserPermissions(login: string, password: string) {
    const credential = this.stringToBase64(`${login}:${password}`);

    this.http
      .get<Response<USER_ROLES[]>>(`${environment.gaiminApi}/admin/roles`, {
        headers: new HttpHeaders({
          Authorization: `Basic ${credential}`
        })
      })
      .subscribe((response) => {
        if (response.success && response.data) {
          this.adminCredential = { token: credential, roles: response.data };
          this.login();
          this.router.navigate(['/pages/platform/user-statistics']);
        } else {
          this.toastrService.danger('', 'Invalid credentials!');
        }
      });
  }

  logout(shouldRedirect: boolean = true) {
    this.isLoggedInSub$.next(false);
    this.adminCredential = { token: null, roles: [] };
    this.currentUserRolesSub$.next([]);
    if (shouldRedirect) {
      this.router.navigate(['/pages/platform-login']);
    }
  }
}
