<div class="header-container">
  <img class="logo" src="../../../../assets/images/gaimin-logo.png" alt="Dashboard" (click)="navigateHome()" />
  <nb-icon icon="menu-outline" (click)="toggleSidebar()"></nb-icon>
</div>
<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.displayName"
        [picture]="user?.photoURL"
        *ngIf="user">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
