import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  NbMediaBreakpointsService,
  NbMenuBag,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService
} from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import firebase from 'firebase/compat/app';
import { LayoutService } from '../../../@core/utils';
import { AuthService as PlatformAuthService } from '../../../shared/services/platform/auth.service';
import { AuthService as ColosseumAuthService } from '../../../shared/services/colosseum/auth.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  userMenu: NbMenuItem[] = [];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    public auth: AngularFireAuth,
    public platformAuthService: PlatformAuthService,
    public colosseumAuthService: ColosseumAuthService
  ) {}

  ngOnInit() {
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe((isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl));

    combineLatest([this.colosseumAuthService.isLoggedIn$, this.platformAuthService.isLoggedIn$]).subscribe(
      ([colosseumLoggedIn, platformLoggedIn]) => {
        this.updateUserMenu(colosseumLoggedIn, platformLoggedIn);
      }
    );

    this.menuService.onItemClick().subscribe((event: NbMenuBag) => {
      if (event.item.data?.id === 'colosseum_logout') {
        this.colosseumAuthService.logout();
      } else if (event.item.data?.id === 'platform_logout') {
        this.platformAuthService.logout();
      } else if (event.item.data?.id === 'google_logout') {
        this.googleLogout();
      }
    });

    this.auth.user.subscribe((user: firebase.User | any) => {
      this.user = user;
    });
  }

  updateUserMenu(colosseumLoggedIn: boolean, platformLoggedIn: boolean) {
    const menuItems: NbMenuItem[] = [
      {
        title: 'Google Logout',
        data: { id: 'google_logout' }
      }
    ];

    if (platformLoggedIn) {
      menuItems.push({
        title: 'Platform Logout',
        data: { id: 'platform_logout' }
      });
    }

    if (colosseumLoggedIn) {
      menuItems.push({
        title: 'Colosseum Logout',
        data: { id: 'colosseum_logout' }
      });
    }

    this.userMenu = menuItems;
  }

  googleLogout() {
    this.auth.signOut().then(() => {
      this.router.navigate(['/auth/login']);
    });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
